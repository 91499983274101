<template>
<footer class="footer text-center">
   <img src="../../assets/img/logo.png " style="width: 12rem;" />
   <div class="row justify-content-center">
      <div class="col-md-4 col-12 p-2">
         <div class="phone-information">
            <i class="bi bi-phone-vibrate"></i>
            <h5>Phone Number:</h5>
            <label>337-326-1000</label>
         </div>
      </div>
      <div class="col-md-4 col-12 p-2">
         <div class="social-media mt-4">
            <h5>Follow Us On Social Media</h5>
            <a href="https://www.instagram.com/infinitymkting/">
               <span class="p-2"><i class="bi bi-instagram icon-footer p-2"></i></span>
            </a>
            <a href="https://www.facebook.com/Infinitymkting">
               <span class="p-2"><i class="bi bi-facebook icon-footer p-2"></i></span>
            </a>
            <a href="https://www.linkedin.com/company/infinity-marketing2">
               <span class="p-2"><i class="bi bi-linkedin icon-footer p-2"></i></span>
            </a>
         </div>
      </div>
      <div class="col-md-4 col-12 p-2">
         <div class="address">
         <i class="bi bi-journal-album"></i>
            <h5>Address:</h5>
            <label>
               1602 W Pinhook Rd <br />
               Suite 202 <br />
               Lafayette LA 70508
            </label>
         </div>
      </div>
   </div>
</footer>
</template>
<style scoped>
 /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/

        footer {
            margin-top: 20px;
            width: 100%;
            height: auto;
        }

        .footer>img {
            width: 20vw !important;
        }

        .phone-information>h5 {
            font-family: Monserrat;
            font-size: 1.4vw;
        }

        .phone-information>label {
            font-family: Monserrat;
            font-size: 1.4vw;
        }

        .phone-information>i {
            font-size: 3vw;
            color: #57cbf5
        }

        .social-media>h5 {
            font-family: Monserrat;
            font-size: 1.3vw;
        }

        .address>i {
            font-size: 3vw;
        }

        .address>h5 {
            font-family: Monserrat;
            font-size: 1.3vw;
        }

        .address>label {
            font-family: Monserrat;
            font-size: 1.3vw;
        }

        .icon-footer {
            background: #57cbf5;
            border-radius: 50%;
            color: black
        }

        @media screen and (max-width:768px) {
            .footer>img {
                width: 10rem !important;
            }
            .phone-information>label {
                font-size: 0.9rem;
            }
            .phone-information>h5 {
                font-size: 0.9rem;
            }
            .phone-information>i {
                font-size: 2rem !important;
            }
            .social-media>h5 {
                font-size: 0.9rem;
            }
            .address>h5 {
                font-size: 0.9rem;
            }
            .address>label {
                font-size: 0.9rem;
            }
            .address>i {
                font-size: 2rem !important;
            }
        }

        .vueperslide__video {
            width: 100% !important;
        }
</style>
