<template>
  <div>
    <div class="resumeService" id="ResumeService">
      <h1 class="mt-4" data-aos="fade-right" data-aos-duration="3000">
        How We Can Help
      </h1>
      <div class="row justify-content-md-center justify-content-center">
        <div
          v-for="services in services"
          :key="services"
          class="col-resume col-lg-2 col-md-4 col-sm-4 col-6 text-center"
          style="align-self: center; justify-self: center"
        >
          <div class="cardBox">
            <label class="title-section text-center">{{ services.name }}</label>
            <div class="card">
              <div
                class="front p-2"
                style="background: linear-gradient(#57cbf5, #1c75bc)"
              >
                <img :src="services.icon" style="width: 100%" />
              </div>
              <div class="back p-2">
                <p class="back-information">
                  {{ services.content }}
                </p>
                <a class="btn-resume" href="/services">
                  <button class="btn btn-primary">Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResumeServices",

  data() {
    return {
      services: [
        {
          name: "Graphic Design",
          icon: require("../../assets/img/services/graphic.png"),
          content: "Professional design work across your brand",
          section: "GraphicDesign",
        },
        {
          name: "Analytics & SEO",
          icon: require("../../assets/img/services/analytic.png"),
          content: "Result Driven Marketing",
          section: "Analytics",
        },
        {
          name: "Social Media",
          icon: require("../../assets/img/services/social-media.png"),
          content: "Increase Your Reach",
          section: "SocialMedia",
        },
        {
          name: "Web Design",
          icon: require("../../assets/img/services/web-desg.png"),
          content: "Legitimize and Standardize  your Company",
          section: "WebDesign",
        },
        {
          name: "Direct Messaging",
          icon: require("../../assets/img/services/direct-messaging.png"),
          content: "Generate More Leads and Sales ",
          section: "MessagingMarketing",
        },
      ],
    };
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
Resume Services
--------------------------------------------------------------*/

.resumeService {
  padding: 2vw 1vw 1vw 1vw;
}

.resumeService > h1 {
  color: white;
  padding: 0 0 0 4vw;
  font-size: 4vw !important;
}

@media screen and (max-width: 592px) {
  .resumeService > h1 {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 300px) {
  .resumeService > h1 {
    font-size: 1.7rem !important;
  }
}
.resumeService > h1 {
  font-family: AzoSans-Black;
}
.title-infinity-color {
  font-size: 3vw !important;
}
.title-infinity-white {
  font-size: 3vw !important;
}
.animate__animated {
  animation-duration: 2s;
}
.tool-card {
  background: linear-gradient(#57cbf5, #1c75bc);
  border-radius: 1rem;
  padding: 0.5rem;
}
.title-section {
  font-size: 2rem;
  height: 8.5vw;
}
.cardBox {
  font-size: 1.2vw;
  margin: 1% 0 0 1%;
  perspective: 900px;
  transition: all 0.3s ease 0s;
  width: 100%;
  padding: 1.3rem;
  position: Relative;
}
.cardBox:hover {
  margin-top: -1.5rem;
}
.cardBox:hover .card {
  transform: rotateY(180deg);
}
.cardBox:hover > label {
  color: #57cbf5;
}
.cardBox > label {
  font-family: AzoSans-Black;
}
.card {
  background: transparent;
  border-style: none;
  cursor: default;
  height: 12.5vw;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  border-radius: 13px;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
}
.col-resume {
  margin-top: 3rem;
}
@media (max-width: 992px) {
  .card {
    height: 11rem;
  }
  .front > img {
    width: 100% !important;
  }
  .btn-primary {
    font-size: 1.2rem !important;
  }
  .btn-primary {
    font-size: 1rem;
  }
}

@media (max-width: 954px) {
  .card {
    height: 14rem;
  }
  .back-information {
    font-size: 1.8rem !important;
  }
}
@media (max-width: 672px) {
  .card {
    height: 16rem;
  }
  .col-resume {
    margin-top: 1rem !important;
  }
}

@media (max-width: 400px) {
  .card {
    height: 9rem;
  }
  .front > img {
    width: 100% !important;
  }
}

.card p {
  margin-bottom: 1.8em;
}
.card .front,
.card .back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  color: white;
  font-size: 1.2em;
  height: 100%;
  border-radius: 14px;
  padding: 0.8em;
  position: absolute;
  text-align: center;
  width: 100%;
  justify-content: center;
  display: inline-grid;
  align-items: center;
}

.card .front strong {
  background: #fff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}
.card .back {
  transform: rotateY(180deg);
  background: rgb(224 224 224);
  box-shadow: grey 10px 10px;
  width: 100%;
}

.card .back a {
  position: absolute;
  width: 100%;
  left: 0rem;
  bottom: -1.5vw;
  padding: 0.3em 0.5em;
  color: #fff;
  text-decoration: none;
  border-radius: 1px;
  font-size: 0.9em;
  transition: all 0.2s ease 0s;
}

.card .back a:hover {
  color: #333;
  text-shadow: 0 0 1px #333;
}
.btn-primary {
  width: 100%;
  color: black;
  font-family: AzoSans-Black;
  background: linear-gradient(
    90deg,
    rgba(87, 203, 245, 1) 38%,
    rgba(63, 168, 222, 1) 55%,
    rgba(28, 117, 188, 1) 80%
  );
  border-radius: 27px;
}
.btn-primary:hover {
  background: #333 !important;
  color: #57cbf5 !important;
}
.back-information {
  font-family: AzoSans-Black;
  font-size: 1.5vw;
  color: black;
  justify-self: center;
}
@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
.btn-resume {
  background: transparent;
}
.icon-sect {
  font-size: 8.5rem !important;
}
.btn-primary {
  font-size: 1vw;
}
@media screen and (max-width: 780px) {
  .back-information {
    font-size: 0.8rem;
  }
  .btn-primary {
    font-size: 0.8rem;
  }
  .title-infinity-color {
    font-size: 1.5rem !important;
  }
  .title-infinity-white {
    font-size: 1.5rem !important;
  }
  .back-information {
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 780px) {
  .back-information {
    font-size: 1.7rem !important;
  }
}
@media screen and (max-width: 560px) {
  .back-information {
    font-size: 1.2rem !important;
  }
  .card {
    height: 11rem;
  }
}
@media screen and (max-width: 414px) {
  .back-information {
    font-size: 1rem !important;
  }
  .card {
    height: 9rem;
  }
  .title-section {
    font-size: 1.4rem !important;
  }
}
@media screen and (max-width: 352px) {
  .back-information {
    font-size: 0.8rem !important;
  }
  .btn-primary {
    font-size: 0.7rem !important;
  }
}
</style>
