<template>
  <div class="animate__animated animate__fadeIn">
    <Navbar></Navbar>
    <div class="logo-main d-xl-none d-md-block d-lg-none d-block">
      <a href="/home">
      <img src="../assets/img/logophone.png" style="width:90%">
      </a>
    </div>

    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
export default {
  name: "AppLayout",
  components: {
    Navbar,
    Footer,

  },
};
</script>
<style scoped>

.logo-main{
  position:absolute;
  z-index:9999;
  width:13vw;
  height:10vw;
  background:white;
  border-radius: 0 0 100%;
}
@media screen and (max-width: 767px) {
  .logo-main{
  width:10rem;
  height:8rem;
}
}
</style>

