<template>
<div class="addServices">
   <h2 class="animate__animated animate__backInLeft">ADDITIONAL SERVICES</h2>
   <div class="content-add-services">
      <div class="div-add-services"></div>
      <div class="container-fluid">
         <vueper-slides class="no-shadow" :autoplay="true" :breakpoints="breakpoints" :gap="10" :dragging-distance="70" :duration="1200">
            <vueper-slide v-for="(slide, i) in slides" :key="i">
               <template #content>
                  <div class="additional-services text-center">
                     <img :src="slide.img" style="justify-self:center" />
                     <h1>{{ slide.addServices }}</h1>
                  </div>
               </template>
            </vueper-slide>
         </vueper-slides>
      </div>
   </div>
</div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "Clients",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      slides: [
        {
          addServices: "Ad Management",
          img: require("../../assets/img/services/manage.png"),
        },
        {
          addServices: "Brand Development",
          img: require("../../assets/img/services/brand-development.png"),
        },
        {
          addServices: "Lead Generation",
          img: require("../../assets/img/services/lead-generation.png"),
        },
        {
          addServices: "Blogs/ Newsletters",
          img: require("../../assets/img/services/blog.png"),
        },
        {
          addServices: "Press Releases",
          img: require("../../assets/img/services/press-releases.png"),
        },
        {
          addServices: "Video Production",
          img: require("../../assets/img/services/pod.png"),
        },
        {
          addServices: "Youtube Content Creation",
          img: require("../../assets/img/services/yout.png"),
        },
      ],
      breakpoints: {
        5600: {
          slideRatio: 1 / 4,
          visibleSlides: 4,
          draggingDistance: 70,
          fixedHeight: "22vw",
          bullets: false,
          arrows: false,
        },
        1024: {
          slideRatio: 1 / 4,
          visibleSlides: 3,
          draggingDistance: 70,
          fixedHeight: "35vw",
          bullets: false,
          arrows: false,
        },
        767: {
          slideRatio: 1 / 4,
          visibleSlides: 2,
          draggingDistance: 70,
          fixedHeight: "50vw",
          bullets: false,
          arrows: false,
        },
      },
    };
  },
};
</script>
<style scoped>
.vueperslide--active {
  border: 3px solid white !important;
  -webkit-transition: 0.3s ease-in-out !important;
  transition: 0.3s ease-in-out !important;
}
.vueperslide--active > img {
  filter: invert(1) sepia(1) hue-rotate(20deg) saturate(1%) !important;
}
.vueperslide {
  top: 2.8vw;
  height: 18vw;
  background-color: white;
  border-radius:14px;
}

@media screen and (max-width: 798px) {
  .vueperslide {
    height: 20vw;
  }
}
@media screen and (max-width: 767px) {
  .vueperslide {
    height: 35vw !important;
  }
}
</style>
