<template>
<div class="content-main-popup d-none d-xl-block">
  <div class="popupConsult" v-if="bolConsultation">
    <div v-if="bolLoad" class="d-flex align-items-center load-contact">
      <div
        class="spinner-border ms-auto"
        style="right: 50%; position: absolute; color: white"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
    <div

      class="consultation-card animate__animated animate__backInDown animate__delay-5s p-4"
    >
      <div class="header-popup">
        <button @click.prevent="Consultation" class="btn p-1 text-center">
          X
        </button>
        <h1 class="text-center">FREE</h1>
        <h2 class="text-center">Consultation</h2>
      </div>
      <div class="container-consultation p-3">
        <form
          class="forms-sample p-4"
          @submit.prevent="sendEmail"
          autocomplete="off"
        >
          <div class="form-group col-12 mt-2">
            <label class="popup-label"
              >Email <span class="text-danger">*</span></label
            >
            <input
              id="email"
              type="email"
              class="form-control"
              v-model="state.email"
              placeholder="Email"
              style="color: black"
            />
            <span class="text-danger text-center" v-if="v$.email.$error">
              {{ v$.email.$errors[0].$message }}
            </span>
          </div>
          <div class="form-group col-12 mt-2">
            <label class="popup-label"
              >Message <span class="text-danger">*</span></label
            >
            <textarea
              id="message"
              type="text"
              class="form-control"
              v-model="state.message"
              placeholder="Message"
            />
            <span class="text-danger text-center" v-if="v$.message.$error">
              {{ v$.message.$errors[0].$message }}
            </span>
          </div>
          <div class="btn-popup-result text-center mt-1">
            <button class="btn" type="submit">Send Email</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
/**
 * @property import files
 */
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { postHttp } from "../../utils/http";
import { apiSendEmail } from "../../views/contact/uriApi";
import Swal from "sweetalert2";

export default {
  /**
   * @property component name
   */
  name: "ContactPage",
  /**
   * @property component initialization
   */
  components: {},
  data() {
    return {
      bolLoad: false,
      bolConsultation: true,
    };
  },
  inject: ["reload"],
  /**
   * @property data initialization
   */

  setup() {
    const state = reactive({
      email: "",
      message: "",
    });
    /**
     * @property data roules
     */
    const rules = computed(() => {
      return {
        email: { required, email },
        message: { required },
      };
    });
    const v$ = useVuelidate(rules, state);

    return {
      state,
      v$,
    };
  },
  methods: {
    async validateForm() {
      this.v$.$validate();
    },
    /**
     * @property Api for sending Email message
     */
    async sendEmail() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.bolLoad = true;
        const response = await postHttp(apiSendEmail, this.state);
        this.bolLoad = true;
        if (response.status == 200) {
          this.bolLoad = false;
          let timerInterval
          Swal.fire({
            title: "<strong>Email sent!!</strong>",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            html: '<h5 style="color:57cbf5">We will contact you shortly</h5>',
            icon: "success",
            background: "#2d2d2d",
            textColor: "black",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: () => {
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          });
          this.bolConsultation= false
         } else {
          console.log("Contact");
        }
      } else {
        console.log("invalid form");
      }
    },
    Consultation() {
      this.bolConsultation = false;
    },
  },
  created() {},
};
</script>
<style scoped>
        /*--------------------------------------------------------------
Popup
--------------------------------------------------------------*/

        .content-main-popup {
            position: absolute;
            top: 35rem;
            width: 100%;
        }

        .consultation-card {
            transform: scale(0.8) !important;
            width: 20rem !important;
            min-width: 20rem !important;
            max-width: 20rem !important;
            position: absolute !important;
            z-index: 999 !important;
            left: 78% !important;
            right: 12rem !important;
            box-shadow: rgb(94 87 87) 20px 20px !important;
            background: #e0e0e0 !important;
            border-radius: 14px !important;
        }

        @media screen and (min-width: 1200px) {
            .consultation-card {
                transform: scale(0.8) !important;
                left: 75% !important;
            }
        }

        @media screen and (max-width: 1173px) {
            .consultation-card {
                left: 70% !important;
            }
        }

        @media screen and (max-width: 990px) {
            .consultation-card {
                left: 65% !important;
            }
        }

        @media screen and (max-width: 844px) {
            .consultation-card {
                left: 55% !important;
            }
        }

        @media screen and (max-width: 766px) {
            .content-main-popup {
                top: 60% !important;
            }
            .consultation-card {
                transform: scale(0.7) !important;
                left: 58% !important;
            }
        }

        @media screen and (max-width: 664px) {
            .content-main-popup {
                top: 50% !important;
            }
            .consultation-card {
                transform: scale(0.6) !important;
                left: 58% !important;
            }
        }

        @media screen and (max-width: 580px) {
            .content-main-popup {
                top: 20% !important;
            }
            .consultation-card {
                transform: scale(0.6) !important;
                left: 20% !important;
            }
        }

        @media screen and (max-width: 340px) {
            .content-main-popup {
                top: 20% !important;
            }
            .consultation-card {
                transform: scale(0.5) !important;
                left: 1% !important;
            }
        }

        .container-consultation {
            border-width: 3px;
            border-style: solid;
            border-color: black;
            width: 100%;
            border-radius: 18px !important;
        }

        .header-popup>button {
            color: Black !important;
            font-family: AzoSans-Black;
            position: absolute;
            background: #38b9e2;
            width: 2rem;
            right: 1rem;
            height: 2rem;
        }

        .header-popup>button:hover {
            background: #2d2d2d;
            color: #38b9e2 !important;
        }

        .header-popup>h1 {
            color: black;
            font-size: 1.8rem;
            font-family: AzoSans-Black !important;
        }

        .header-popup>h2 {
            color: black;
            font-size: 1.8rem;
            font-family: AzoSans-Black !important;
        }

        .popup-label {
            font-size: 1rem;
            color: black;
            font-family: AzoSans-Black;
        }

        .btn-popup-result>button {
            background: #45d4ff !important;
            width: 100%;
            font-size: 1rem;
            font-family: AzoSans-Black !important;
        }

        .btn-popup-result>button:hover {
            background: #404041 !important;
            color: #45d4ff;
        }

        .container-consultation>button {
            background: #38b9e2 !important;
            color: black !important;
            font-size: 1rem;
            font-weight: bold !important;
            font-family: AzoSans-Black !important;
        }

        .container-consultation>button:hover {
            background: #2d2d2d !important;
            color: #38b9e2 !important;
        }

        .container-consultation>input {
            border: 2px solid black !important;
        }

        .container-consultation>textarea {
            border: 2px solid black !important;
        }

</style>

