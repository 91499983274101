<template>
  <div class="resultSection">
    <div class="row justify-content-center">
      <div
        class="
          col-lg-6 col-md-5 col-sm-6
          p-4
          col-11
          order-lg-1 order-sm-1 order-2
        "
        style="align-self: center; justify-self: center"
      >
        <div class="result-content">
          <h2 data-aos="fade-right" data-aos-duration="1000">
            Learn About the Marketing Firm that is <br />
          </h2>
          <h3>Like No Other</h3>
          <p class="text-result" data-aos="fade-right" data-aos-duration="1000">
            Forged from the Oil and Gas industry, Infinity Marketing is creative
            digital marketing for the O&G industry. Infinity Marketing is a
            data-driven marketing firm that utilizes a combination of design,
            analytics, strategy, and direct marketing to provide top-notch
            marketing services. Infinity is the right choice because We Know Oil
            and Gas.
          </p>
          <a href="/about" class="btn-results" data-aos="fade-right">
            <button class="btn btn-primary">Read More</button>
          </a>
        </div>
      </div>
      <div
        class="
          col-lg-5 col-md-6 col-sm-5 col-12
          order-lg-2 order-sm-2 order-1
          pr-4
          text-center
        "
      >
        <div class="result-image">
          <img
            src="../../assets/img/result.png"
            style="width: 100%; object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.resultSection {
  margin-top: 3vw;
}
.result-image {
  width: 100%;
  height: 100%;
}
.icon-sect {
  font-size: 18rem;
  color: #1c75bc !important;
}
.icon-results {
  font-size: 20vw;
  color: white;
}
.result-section {
  background: transparent;
}
.img-results {
  border-radius: 1rem;
  border-width: 0.5vw;
  border-style: solid;
  border-color: white;
  height: 100%;
}
.btn-primary {
  background: linear-gradient(
    90deg,
    rgba(87, 203, 245, 1) 38%,
    rgba(63, 168, 222, 1) 55%,
    rgba(28, 117, 188, 1) 80%
  );
  color: white;
  border-radius: 27px;
  font-family: AzoSans-Black;
  font-size: 1.7vw;
  width: 20rem;
  max-width: 20rem;
  text-align: center;
}
.btn-primary:hover {
  background: #2d2d2d;
  color: #57cbf5;
}
.text-result {
  font-size: 2vw;
}
.result-content > h2 {
  font-size: 2.9vw;
  color: white;
  font-family: AzoSans-Black !important;
}
.result-content > h3 {
  font-size: 3vw;
  color: #57cbf5;
}
.result-content > p {
  font-size: 1.2vw;
  font-family: Monserrat !important;
}
@media screen and (max-width: 600px) {
  .result-content > h2 {
    font-size: 2rem !important;
  }
  .result-content > h3 {
    font-size: 2rem !important;
  }
  .result-content > p {
    font-size: 1rem;
  }
  .btn-primary {
    font-size: 1.2rem;
    width: 100%;
  }
}
</style>
