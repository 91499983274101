<template>
  <div>
    <Carousel></Carousel>
    <Resume></Resume>
    <Results></Results>
    <Clients></Clients>
    <Contact></Contact>
  </div>
</template>
<script>
import Carousel from "../../components/carousel/Carousel.vue";
import Resume from "./ResumeServices.vue";
import Results from "./Results.vue";
import Clients from "./Clients.vue";
import Contact from "../contact/Contact.vue";
export default {
  name: "Home",
    components: {
    Carousel,
    Resume,
    Results,
    Clients,
    Contact,
  },

};
</script>

