<template>
  <div class="teams">
    <h1>OUR TEAM</h1>
    <div class="team-content">
      <div class="row justify-content-md-center justify-content-center">
        <div
          v-for="team in items"
          :key="team"
          class="
            col-lg-3 col-md-3 col-sm-5 col-12
            offset-lg-1 offset-md-0 offset-sm-0
            text-center
            mt-2
            p-2
          "
        >
          <div
            class="col-team"
            :data-aos="team.animation"
            :data-aos-delay="team.delay"
            data-aos-duration="4000"
            v-bind:style="{
              top: team.top + 'vw',
              left: team.left + 'vw',
              background: team.background,
            }"
          >
            <h3>{{ team.name }} <br />{{ team.lastname }}</h3>
            <img :src="team.image" /> <br />
            <label v-bind:style="{ top: team.topWorload + 'vw' }">
              {{ team.workload }}</label
            >
          </div>
        </div>
      </div>
      <div class="rect-content"></div>
    </div>
    <div class="information-team p-4" data-aos="fade-down-right">
      <p>
        The Infinity team is made up of individuals with diverse skillsets,
        exceptional experience in design, strategy, writing, and digital
        marketing, along with the utmost passion for marketing. Our experience
        stretches over many years and across a multitude of different
        industries. With experience ranging from high-end marketing industries
        such as the casino industry and niche market industries like the oil and
        gas industry, Infinity Marketing has the knowledge and experience to
        produce magnificent marketing services for you and your company.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Teams",
  components: {},
  data() {
    return {
      items: [
        {
          image: require("../../assets/img/team1.jpg"),
          name: "Ashby ",
          lastname: "Pettigrew",
          workload: "President",
          position: 0,
          top: "-9",
          left: "-1",
          topWorload: "5",
          background: "#187598",
          animation: "flip-left",
          delay: "50",
        },
        {
          image: require("../../assets/img/team2.png"),
          name: "Emile ",
          lastname: "Stretcher",
          workload: "Marketing Director",
          position: 1,
          top: "1",
          left: "-4",
          topWorload: "5",
          background: "#278DAD",
          animation: "zoom-in",
          delay: "250",
        },

        {
          image: require("../../assets/img/team3.jpg"),
          name: "Design",
          lastname: "Team",
          workload: "Lead Design",
          position: 2,
          top: "14",
          left: "-7",
          topWorload: "5",
          background: "#38B9E2",
          animation: "flip-right",
          delay: "350",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
</style>
