<template>
  <div>
    <div v-if="bolLoad" class="d-flex align-items-center load-contact">
      <div
        class="spinner-border ms-auto"
        style="right: 50%; position: absolute; color: white"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
    <div class="topPageContact">
      <img src="../../assets/img/contact.jpeg" />
      <h3 class="animate__animated animate__bounceInUp">
        Reach Out Today For a
      </h3>
      <h2 class="animate__animated animate__bounceInUp">
        FREE <span>Consultation</span>
      </h2>
    </div>
    <div id="contact" class="contact-container">
      <div class="row" data-aos="fade-right" data-aos-duration="1000">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img
            src="../../assets/img/contactSec.jpeg"
            style="width: 100%; object-fit: cover; height: 100%"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-contact" style="padding: 1rem 2rem 0 3rem">
            <h1>Let's Talk!</h1>
          </div>

          <div class="container" style="padding: 1rem 2rem 2rem 3rem">
            <form
              class="forms-sample p-4"
              @submit.prevent="sendEmail"
              autocomplete="off"
              style="background: #1c1c1c0f"
            >
              <div class="row">
                <div class="form-group col-12">
                  <label>Name <span class="text-danger">*</span></label>
                  <input
                    id="first_name"
                    type="text"
                    class="form-control"
                    v-model="state.name"
                    placeholder="First Name"
                  />
                  <span class="text-danger text-center" v-if="v$.name.$error">
                    {{ v$.name.$errors[0].$message }}
                  </span>
                </div>
                <div class="form-group col-12 mt-2">
                  <label>Company <span class="text-danger">*</span></label>
                  <input
                    id="company"
                    type="text"
                    class="form-control"
                    v-model="state.company"
                    placeholder="Company Name"
                  />
                  <span
                    class="text-danger text-center"
                    v-if="v$.company.$error"
                  >
                    {{ v$.company.$errors[0].$message }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12 mt-2">
                  <label>Phone Number <span class="text-danger">*</span></label>
                  <input
                    id="phone_number"
                    type="text"
                    class="form-control"
                    v-model="state.phone_number"
                    placeholder="Subject"
                  />
                  <span
                    class="text-danger text-center"
                    v-if="v$.phone_number.$error"
                  >
                    {{ v$.phone_number.$errors[0].$message }}
                  </span>
                </div>
                <div class="form-group col-12 mt-2">
                  <label>Email <span class="text-danger">*</span></label>
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    v-model="state.email"
                    placeholder="Email"
                  />
                  <span class="text-danger text-center" v-if="v$.email.$error">
                    {{ v$.email.$errors[0].$message }}
                  </span>
                </div>
                <div class="form-group col-12 mt-2">
                  <label>Message <span class="text-danger">*</span></label>
                  <textarea
                    id="message"
                    type="text"
                    class="form-control"
                    v-model="state.message"
                    placeholder="Message"
                  />
                  <span
                    class="text-danger text-center"
                    v-if="v$.message.$error"
                  >
                    {{ v$.message.$errors[0].$message }}
                  </span>
                </div>
              </div>
              <div class="row mt-4 justify-content-center">
                <div class="col-lg-6 col-md-12 col-sm-8 col-12 p-2">
                  <a
                    href="https://calendly.com/emileinfinity/30min-60min "
                    target="_blank"
                  >
                    <div class="btn btn-results text-center mt-1">
                      Schedule A Meeting
                    </div>
                  </a>
                </div>
                <div
                  class="
                    col-lg-6 col-md-12 col-sm-4 col-12
                    p-2
                    align-self-center
                  "
                >
                  <div class="btn-results text-center mt-1">
                    <button class="btn" type="submit">Send Email</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @property import files
 */
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { postHttp } from "../../utils/http";
import { apiSendEmail } from "./uriApi";
import Swal from "sweetalert2";

export default {
  /**
   * @property component name
   */
  name: "ContactPage",
  /**
   * @property component initialization
   */
  components: {},
  data() {
    return {
      bolLoad: false,
    };
  },
  inject: ["reload"],
  /**
   * @property data initialization
   */

  setup() {
    const state = reactive({
      name: "",
      company: "",
      phone_number: "",
      email: "",
      message: "",
    });
    /**
     * @property data roules
     */
    const rules = computed(() => {
      return {
        name: { required },
        company: { required },
        phone_number: { required, numeric },
        email: { required, email },
        message: { required },
      };
    });
    const v$ = useVuelidate(rules, state);

    return {
      state,
      v$,
    };
  },
  methods: {
    async validateForm() {},
    /**
     * @property Api for sending Email message
     */
    async sendEmail() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.bolLoad = true;
        const response = await postHttp(apiSendEmail, this.state);
        if (response.status == 200) {
          this.bolLoad = false;
          let timerInterval;
          Swal.fire({
            title: "<strong>Email sent!!</strong>",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            html: '<h5 style="color:57cbf5">We will contact you shortly</h5>',
            icon: "success",
            background: "#2d2d2d",
            textColor: "black",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: () => {
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        } else {
          console.log("Contact");
        }
      } else {
        console.log("invalid form");
      }
    },
  },
  created() {
    //console.log('trueasa',Promise.reject(this.v$.$validate()))
  },
};
</script>
<style scoped>
.form-control {
  color: white !important;
}
.form-text-contact {
  font-size: 1rem;
}

.contact-container {
  margin-top: 4vw;
}
.btn-results {
  background: #45d4ff !important;
  font-size: 1.2rem;
  font-family: AzoSans-Black !important;
  width: 100%;
  border-radius: 10px !important;
}
.btn-results:hover {
  background: #404041 !important;
  color: #45d4ff;
}
.btn-results > button {
  background: #45d4ff !important;
  font-size: 1.2rem;
  font-family: AzoSans-Black !important;
  width: 100%;
  border-radius: 10px !important;
}

.btn-results > button:hover {
  background: #404041 !important;
  color: #45d4ff;
}
.form-text-contact {
  font-size: 1vw;
  font-family: Acumin-Regular;
}

@media screen and (max-width: 767px) {
  .form-text-contact {
    font-size: 1rem;
  }
  .btn-results > button {
    font-size: 1rem;
  }
}
.load-contact {
  position: fixed;
  top: 0;
  left: 0;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  border-style: none;
  opacity: 0.8;
  z-index: 9999;
  filter: alpha(opacity=60);
}
</style>

