
<template>
  <div>
    <div class="content-carousel">
     <video id="video-gif" poster="../../assets/img/1.png" playsinline muted loop  style="width:100%">
      <source type="video/mp4" src="../../assets/img/infinity.mp4"/>
       <source type="video/ogg" src="../../assets/img/infinity.ogg"/>
       <source type="video/webm" src="../../assets/img/infinity.webm"/>
      </video >
      <!--img src="../../assets/img/infinity-new.gif" !-->
    </div>
    <a href="#ResumeService">
      <h4 class="click-home">Great Marketing is Just a Click Away!</h4>
    </a>
      <popup></popup>

  </div>

  <!--vueper-slides bullets-outside :dragging-distance="50" fixedHeight="100vh">
  <vueper-slide
    v-for="(slide, i) in slides"
    :key="i"
    :image="slide.image"
    :video="slide.video" />
    </vueper-slides>
  </div>

  <div class="content-main-popup" style="visibility:hidden">
      <popup></popup>
    </div>
  </div-->
</template>
<script>
//import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import popup from "../../components/popup/Popup.vue";
export default {
  name: "CarouselMain",
  components: {
  //   VueperSlides,
  //  VueperSlide,
    popup,
  },
  mounted(){
     document.querySelector('video').load();
      document.querySelector('video').play();
  },
  data() {
    return {
      /*slides: [
        {
          image: require("../../assets/img/mainpage.gif"),
          content: "Digital Marketing For the",
          content2: "Oil and Gas Industry",
        },
      ],*/
      slides: [
        {
          image: `../../assets/img/1.png`,
          video: {
            mp4:  `${process.env.BASE_URL}images/marketing.mp4`,
            props: { autoplay: true, loop: true, controls: false, muted: true , width:2000, height:1000}
          }
        }
      ],
      breakpoints: {
        5600: {
          fixedHeight: "92vh",
        },
        1032: {
          fixedHeight: "92vh",
          arrows: false,
        },
      },
    };
  },

};
</script>
<style scoped>
.vueperslide__video {
    outline: none;
    width: 100% !important;
    height: 100% !important;
}
video{
     width: 100% !important;
    height: 100% !important;
}
#video-gif{
  margin-top:4.4rem !important;
}
@media screen and (max-width:937px){
  #video-gif{
  margin-top:3rem !important;
}
}
</style>
