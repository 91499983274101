<template>
  <div class="Services">
    <div class="topPageService">
      <img src="../../assets/img/service.jpeg" />
      <h3 class="animate__animated animate__bounceInUp">
        Result Driven Marketing
      </h3>
      <h2 class="animate__animated animate__bounceInUp">Infinite Solutions</h2>
    </div>
    <div class="service-content">
      <h1>What We Do</h1>
      <div class="row">
        <div
          class="
            col-md-6 col-sm-6 col-12
            text-center
            mt-2
            p-4
            align-items-center
          "
          v-for="service in services"
          :key="service"
          data-aos="zoom-in"
        >
          <div
            :id="service.id"
            class="service-info animate__animated animate__backInLeft"
            :style="{
              'background-image':
                'url(' +
                require(`@/assets/img/services/${service.backpicture}`) +
                ')',
            }"
          >
            <div class="container-icon">
              <img class="icon-sect" :src="service.icon" />
            </div>
            <h3 class="text-center">{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <AdicionalServices></AdicionalServices>
  </div>
</template>
<script>
import AdicionalServices from "./AdditionalServices.vue";
export default {
  name: "Services",
  components: {
    AdicionalServices,
  },
  data() {
    return {
      services: [
        {
          id: "SocialMedia",
          backpicture: "1.jpg",
          title: "Social Media",
          icon: require("../../assets/img/services/social-media.png"),
          description:
            "Social Media taking too much of your time? Overwhelmed? Or is your online presence nonexistent? Infinity Marketing can take the load off and provide superb Social Media marketing from strategy, design, and posting.",
        },
        {
          id: "GraphicDesign",
          backpicture: "2.jpg",
          title: "Graphic Design",
          icon: require("../../assets/img/services/graphic.png"),
          description:
            "Design work can be very stressful and take up a lot of time. Whether it is design work for social media, print, web design, or any other design needs, Infinity Marketing can handle it all for you. Save time and cut out stress with us.",
        },
        {
          id: "Analytics",
          backpicture: "3.jpg",
          title: "Analytics & SEO",
          icon: require("../../assets/img/services/analytic.png"),
          description:
            "Result-driven marketing is what we do. See the results of your marketing efforts through analytics and reporting. Through our processes and strategy, we can assist with higher rankings on search engines and more reach on social media.",
        },
        {
          id: "WebDesign",
          backpicture: "4.png",
          title: "Web Design",
          icon: require("../../assets/img/services/web-desg.png"),
          description:
            "Need a good, functioning website to legitimize your company or make things easier for potential customers? Have a need for a new website but don’t want to spend every penny on making a new one? Infinity Marketing can assist you by designing a website with you at an affordable cost.",
        },
        {
          id: "MessagingMarketing",
          backpicture: "5.jpg",
          title: "Direct Messaging Marketing",
          icon: require("../../assets/img/services/ICONO-6.png"),
          description:
            "DM marketing is a highly used industry practice that has existed since the very beginnings of marketing. The only difference is the formats have changed. Instead of flyers and direct mail, it is now direct emails and text messages through the use of E-blasts.",
        },
        {
          id: "StrategicPlanning",
          backpicture: "6.jpg",
          title: "Strategic Planning",
          icon: require("../../assets/img/services/strategic-plan.png"),
          description:
            "Just like every good business needs a good business plan, every good marketing team needs a great marketing plan. We combine efforts with you to strategize the best plan of approach to better build your brand, grow your reach, and assist in leading to more sales.",
        },
      ],
    };
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
Services Page
--------------------------------------------------------------*/

.service-content {
  padding: 2vw 5vw 1vw 6vw;
}

.service-content > h1 {
  text-align: center;
  color: #57cbf5;
  font-family: AzoSans-Black;
  font-size: 4.8vw;
}

.service-info {
  background-size: cover;
  background-color: #1d1d1eed;
  background-blend-mode: multiply;
  border-radius: 14px;
  padding: 1rem;
  height: 33vw;
  display: grid;
  align-items: center !important;
}

.container-icon {
  background: linear-gradient(
    180deg,
    rgba(87, 203, 245, 1) 25%,
    rgba(28, 117, 188, 1) 80%
  );
  border-radius: 14px;
  width: 10vw;
  padding: 1vw;
  margin: 0 auto;
}

.container-icon > i {
  font-size: 5vw;
}

.service-info > h3 {
  font-size: 3vw;
  font-family: AzoSans-Black;
}

.service-info > p {
  font-size: 1.3vw;
  text-align: justify;
  font-family: Monserrat;
}

@media screen and (max-width: 828px) {
  .service-info {
    height: 32vw !important;
  }
  .service-content > h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 564px) {
  .service-info > p {
    font-size: 0.8rem;
  }
  .service-info > h3 {
    font-size: 1.5rem;
  }
  .service-info {
    height: auto !important;
  }
  .container-icon {
    width: 10rem;
    margin: 0 auto;
  }
  .container-icon > i {
    font-size: 5rem;
  }
  .service-content > h1 {
    font-size: 2rem;
  }
}
</style>
