<template>
  <!-- ======= Header ======= -->
  <header id="header" class="d-flex align-items-center">
    <div
      class="
        container-fluid
        d-flex
        align-items-center
        justify-content-lg-between
      "
    >
      <nav
        id="navbar"
        class="navbar order-last order-lg-0"
        :class="[isCollapse ? 'navbar-mobile' : 'navbar']"
      >
        <ul>
          <li><a id="home" class="nav-link scrollto" href="/home">Home</a></li>
          <li>
            <a id="about" class="nav-link scrollto" href="/about">About</a>
          </li>
          <li id="section">
            <a
              id="main-logo"
              class="nav-link d-none d-lg-block d-xl-block"
              href="/home"
            >
              <div id="logo" class="logo">
                <img
                  id="master"
                  class="logo1"
                  src="../../assets/img/logo1.png"
                />
              </div>
            </a>
          </li>
          <li>
            <a id="services" class="nav-link scrollto" href="/services"
              >Services</a
            >
          </li>
          <li>
            <a id="contact" class="nav-link scrollto" href="/contact"
              >Contact</a
            >
          </li>
          <div class="btn-meeting">
            <a
              href="https://calendly.com/emileinfinity/30min-60min "
              target="_blank"
              style="padding: 0 !important;"
            >
              <div class="btn btn-results text-center mt-1">
                Schedule A Meeting
              </div>
            </a>
          </div>
        </ul>
        <i
          class="bi mobile-nav-toggle"
          :class="[isCollapse ? 'bi-x' : 'bi-list']"
          @click="isCollapse = !isCollapse"
        ></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
  <!-- End Header -->
</template>

<script>
export default {
  /**
   * @property component name
   */
  name: "NavBar",
  data: () => ({
    isCollapse: false,
    Arraypath: [
      { path: "/home", id: "home" },
      { path: "/about", id: "about" },
      { path: "/services", id: "services" },
      { path: "/contact", id: "contact" },
    ],
  }),
  methods: {
    activeClass() {
      for (let i = 0; i < this.Arraypath.length; i++) {
        console.log(this.Arraypath[i].path);
        if (window.location.pathname == this.Arraypath[i].path) {
          var div = document.getElementById(this.Arraypath[i].id);
          console.log(div);
          div.classList.add("active");
        }
      }
    },
  },
  mounted() {
    this.activeClass();
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #57cbf5;
  transition: all 0.5s;
  z-index: 9999;
  position: fixed !important;
  height: 70px;
  width: 100%;
}
@media (max-width: 992px) {
  #header {
    height: 60px;
  }
}
#header.fixed-top,
#header.header-inner-pages {
  background: rgba(21, 5, 23, 0.8);
}
#header.fixed-top {
  position: fixed;
}

.scrolled-offset {
  margin-top: 70px;
}
@media (max-width: 991px) {
  .scrolled-offset {
    margin-top: 60px;
  }
}

#main-logo {
  width: 8rem;
}
/*--------------------------------------------------------------
# Header Social Links
--------------------------------------------------------------*/
.header-social-links {
  padding-left: 20px;
}
.header-social-links a {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.header-social-links a i {
  line-height: 0;
}
.header-social-links a:hover {
  color: #fff;
}
@media (max-width: 768px) {
  .header-social-links {
    padding: 0 15px 0 0;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
  width: 100%;
}
.navbar ul {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 12px;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 1.4rem;
  font-family: AzoSans-Black;
  font-weight: 600;
  white-space: nowrap;
  transition: 0.3s;
  color: white;
  position: relative;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -7px;
  left: 0;
  background-color: white;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

#main-logo:before {
  background-color: transparent;
}
.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 12px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #150517;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #57cbf5;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  position: relative;
  left: 90%;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
    position: relative;
    left: 90%;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 50%;
  bottom: 0;
  background: #57cbf5;
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: white;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #150517;
}
.navbar-mobile > ul > li {
  padding: 0;
}
.navbar-mobile a:hover:before,
.navbar-mobile li:hover > a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #57cbf5;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #57cbf5;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}
#section {
  visibility: visible;
}

@media screen and (min-width: 992px) {
  .logo {
    display: block;
    margin: 0 0 0 -40px;
    padding: 1vw 1vw 2vw;
    position: absolute;
    top: -2vw;
    left: 50.5%;
    z-index: 2;
  }
  .logo:before {
    display: block;
    margin-top: -0.5vw;
    position: absolute;
    top: 3.5rem;
    left: -1rem;
    width: 0;
    height: 0;
    border-left: 4rem solid transparent;
    border-right: 4rem solid transparent;
    border-top: 4rem solid #57cbf5;
    content: "";
    z-index: 1;
  }
  .logo1 {
    width: 8rem;
    z-index: 99;
    height: 4rem;
    left: -2rem;
    position: relative;
    top: -0.8rem;
  }
}
.bi-x {
  left: 40%;
}
.btn-meeting {
  position: absolute;
  right: 0;
  left: auto;
  background: white;
  border-radius: 13px;
}
@media (max-width: 992px) {
  .btn-meeting {
    position: initial !important;
    background: transparent !important;
  }
}
</style>
