import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    throttleDelay: 50,
    offset: 130,
    anchorPlacement: 'top-bottom',
    once: false,

});


createApp(App).use(store).use(router).mount('#app')