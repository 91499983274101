<template>
<div class="about">
   <div class="topPageAbout">
      <img src="../../assets/img/about.jpeg" />

       <h3 class="animate__animated animate__bounceInUp animate__slow p-3">We Are Marketing</h3>
      <h2 class="animate__animated animate__bounceInUp animate__slow">For The Oil And Gas Industry</h2>

   </div>
       <div class="content-about">
      <h1 data-aos="fade-right">ABOUT US</h1>
      <h2 data-aos="fade-right">
         Results Driven Marketing With Infinite Solutions
      </h2>
      <p data-aos="fade-right">
         Infinity Marketing is a marketing firm that was derived from the O&G industry geological service company, Stratagraph Inc. which has been around for over 60 years. Originally the exclusive marketing department of Stratagraph, Inc.,
         Infinity Marketing was branched off in order to deliver result-driven, corporate structure marketing services to other companies in the industry. Businesses can now receive services from a marketing agency that knows and
         understands both marketing and the oil and gas industry.
      </p>
      <p data-aos="fade-right">
         Infinity Marketing is a data-driven marketing firm that utilizes a combination of design, analytics, strategy, and direct marketing to provide top-notch marketing services. Your company can be the next to jump on the train and
         receive marketing from an agency that is rooted in the oil and gas industry! Choose Infinity Marketing because WE KNOW OIL AND GAS.
      </p>
   </div>
   <team></team>
</div>

</template>
<script>
 import team from "./Teams.vue"
export default{
  name:'About',
  components:{
   team
  },

}
</script>

