<template>
  <div class="clients-container" data-aos="zoom-in">
    <h1 class="title-infinity-white text-center">Featured Clients</h1>
    <div class="section-clients">
      <vueper-slides
        class="no-shadow text-center"
        :bullets="false"
        :gap="3"
        :arrows="false"
        :autoplay="true"
        :visible-slides="4"
        :breakpoints="breakpoints"
        :dragging-distance="40"
        :duration="1200"
        style="top: 2rem"
      >
        <vueper-slide v-for="(slide, i) in slides" :key="i">
          <template #content>
            <div class="center-slide">
              <img
                class="img-clients"
                v-bind:style="{ width: slide.width + 'vw' }"
                :src="slide.imageAdd"
                style="justify-self: center; width:100%"
              />
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "Clients",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      isActive: true,
      slides: [
        {
          imageAdd: require("../../assets/img/client-1.png"), //intelligent
        },
        {
          imageAdd: require("../../assets/img/client-3.png"), //Ascendant
        },
        {
          imageAdd: require("../../assets/img/client-5.png"), //Ascendant
        },
        {
          imageAdd: require("../../assets/img/client-4.png"), //CoreSpack, // Stratachem //StrataYiu
        },
      ],
      breakpoints: {
        5600: {
          slideRatio: 1 / 4,
          visibleSlides: 4,
          draggingDistance: 70,
          fixedHeight: "15vw",
        },
        1024: {
          slideRatio: 1 / 4,
          visibleSlides: 3,
          draggingDistance: 70,
          fixedHeight: "25vw",
        },
        767: {
          slideRatio: 1 / 4,
          visibleSlides: 2,
          draggingDistance: 70,
          fixedHeight: "30vw",
        },
      },
    };
  },
  methods: {},
};
</script>
<style scoped>
/*--------------------------------------------------------------
Clients Page
--------------------------------------------------------------*/
.title-infinity-white {
  font-size: 5vw;
}
.clients-container {
  background: transparent;
  margin-top: 4vw;
}
.section-clients {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  -moz-border-image: -moz-linear-gradient(top left, #57cbf5 50%, #1c75bc 100%);
  -webkit-border-image: -webkit-linear-gradient(
    top left,
    #57cbf5 50%,
    #1c75bc 100%
  );
  border-image: linear-gradient(to bottom right, #57cbf5 50%, #1c75bc 100%);
  border-image-slice: 1;
}

.img-clients {
  vertical-align: middle;
  -webkit-filter: invert(1) sepia(1) hue-rotate(20deg) saturate(40%);
  filter: invert(1) sepia(1) hue-rotate(20deg) saturate(1%);
}

.img-clients:hover {
  filter: none !important;
}

.vueperslides__arrows--outside .vueperslides__arrow--next {
  right: 0 !important;
  visibility: hidden !important;
}

.vueperslide--visible {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.vueperslide--active {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: 0.3s ease-in-out !important;
  transition: 0.3s ease-in-out !important;
}

.vueperslides__arrows--outside {
  color: transparent !important;
}

.vueperslides__track-inner {
  text-align: center !important;
}

.btn-results {
  width: 50%;
  border-radius: 1rem;
  border-color: transparent !important;
  background: linear-gradient(to right, #57cbf5, #1c75bc) !important;
}

.btn-section {
  width: 80%;
  border-radius: 1rem;
  border-color: transparent !important;
  background: linear-gradient(to right, #57cbf5, #1c75bc) !important;
  bottom: -1.5vw;
  position: absolute;
  left: 1.5vw;
}
.center-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.vueperslide {
  display: flex;
}
</style>

