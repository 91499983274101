import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/Home.vue'
import About from '../views/about/About.vue'
import AppLayout from '../views/AppLayout.vue'
import PathNotFound from '../views/PathNotFound.vue'
import Services from '../views/services/Services.vue'
import ContactPage from '../views/contact/ContactPage.vue'

const routes = [
    { path: '/:pathMatch(.*)*', component: PathNotFound },
    {
        path: "/",
        redirect: '/home',
        name: 'AppLayout',
        component: AppLayout,
        children: [

            {
                path: '/home',
                name: 'Home',
                component: Home,
            },
            {
                path: '/about',
                name: 'About',
                component: About,
            },
            {
                path: '/services',
                name: 'Services',
                component: Services,
            },
            {
                path: '/contact',
                name: 'ContactPage',
                component: ContactPage,
            }
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        return { top: 0 };
    },
    routes
})


export default router