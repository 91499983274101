import axios from 'axios';

axios.defaults.baseURL = "https://ascendant-back.herokuapp.com"

/**
 * @description post method for authentication login
 * @param {String} url post type api url
 * @param {Object} body object to be consulted by means of the api
 * @return {Object}
 */
export const postHttp = async function(url, body) {
    return await axios.post(url, body).then(response => {
        return response
    }).catch(error => {
        return error.response
    });
}
